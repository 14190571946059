import React from "react"
import { Card, Row, Col, Container } from "react-bootstrap"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const pearsonAward = () => {
  return (
    <Layout>
      <Helmet>
        <title>Pearson Award - Pamela Mary Brown</title>
      </Helmet>
      <h2>Pearson Award</h2>
      <Container>
        <Row style={{ marginBottom: "30px" }}>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            style={{ paddingLeft: "0px", height: "100%" }}
          >
            <StaticImage
              src={"../images/PamelaPearson11.jpg"}
              alt={"Pamela Pearson Award Picture"}
            />
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
          >
            <Card.Text>
              ‘The Gallery of Greats,’ an inspiring photography exhibition
              created to celebrate the Teaching Awards Trust’s 25th year
              anniversary and showcase a small selection of the amazing Silver
              winners from the Pearson National Teaching Awards, 2023. Captured
              by photographer Donna Bridgewater photographer, teacher, and
              educator based in Coventry, recently shortlisted for the Royal
              Photographic Society’s International Photography Exhibition,
              photographed the educators in their working environment.
              <a
                href="https://www.teachingawardstrust.com/the-gallery-of-greats-exhibition/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                The Gallery of Greats: Celebrating Life-Changing Educators -
                Teaching Awards Trust
              </a>
            </Card.Text>

            <Card.Text style={{ display: "flex", justifyContent: "center" }}>
              Pamela Brown, further education lecturer, North West Regional
              College, Derry. Shortlisted for FE Lecturer of the Year.
            </Card.Text>

            <Card.Text style={{ display: "flex" }}>
              ‘Transforming futures through education.’
            </Card.Text>
            <Card.Text style={{ display: "flex", justifyContent: "center" }}>
              Pamela, an educator at HMP Magilligan since 2011, excels in
              delivering Open College Network (OCN) accreditation. Remarkably,
              her prison students have achieved a 100% completion rate, a
              testament to her dedication.
            </Card.Text>

            <Card.Text style={{ display: "flex", justifyContent: "center" }}>
              Transforming futures through education, Pamela recognises the
              power of creative writing within prison walls, helping inmates
              re-evaluate themselves, maintain family connections, and navigate
              challenging relationships during their confinement.
            </Card.Text>
            <Card.Text style={{ display: "flex", justifyContent: "center" }}>
              Pamela is pictured here in one of her classrooms where she
              delivers life-changing lessons.
            </Card.Text>
          </Col>
        </Row>

        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            style={{
              paddingLeft: "0px",
              height: "60%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <StaticImage
              src={"../images/PamelaPearsonAward.jpg"}
              alt={"Pamela Pearson Award"}
              height='60%'
            />
          </Col>
        </Row>

        <Row>
          <Card.Text style={{ marginTop: "10px" }}>
            Pamela has been teaching in HMP Magilligan since 2011, delivering
            Open College Network (OCN) accreditation. During her time as a
            prison educator, all her students have completed their accreditation
            and she has a 100% retention record.
          </Card.Text>
          <Card.Text>
            Pamela believes that supporting creative writing in prison provides
            a vital role in the lives of prisoners: how they re-evaluate
            themselves; how they stay connected with their families and manage
            the survival of these relationships under duress.
          </Card.Text>
          <Card.Text>
            Pamela offers a range of teaching strategies to help her students
            develop writing skills, literacy, self-advocacy and self-reflection.
            The awards they receive yearly are outstanding, with submissions to
            Listowel Prison Writing and Koestler Arts Awards, and student work
            exhibited in London’s South Bank Centre. Working with Pamela,
            students gain skills in compiling books of poetry, anthologies,
            memoirs, short stories, and article writing. They also adapt to
            teamwork activities easily transferrable to daily interactions and a
            work environment.
          </Card.Text>
          <Card.Text style={{ marginBottom: "25px" }}>
            During the pandemic, Pamela created over 100 writing resources,
            including a resource on the European Prison Education Association
            (EPEA) website.
          </Card.Text>
        </Row>

        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            style={{
              paddingLeft: "0px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <StaticImage
              src={"../images/PamelaPearson1.jpg"}
              alt={"Pamela Pearson Award 1"}
            />
          </Col>
        </Row>
        <Row>
          <Card.Title style={{ marginTop: "15px" }}>
            Pearson Silver Award for Further Education Lecturer of the Year
          </Card.Title>
          <Card.Text>
            A lecturer from North West Regional College (NWRC) has been
            presented with the Silver Award for Further Education Lecturer of
            the Year, recognising her outstanding commitment to changing lives
            at HMP Magilligan, every day.
          </Card.Text>
          <Card.Text>
            Pamela Brown has been teaching Creative Writing courses at the
            Prison since 2011 and fully understands the impact and importance of
            her courses on the lives of her students.
          </Card.Text>
          <Card.Text>
            She is also the editor of prison magazine Time In, and co-edits the
            magazine, All In, both of which provide major curricular activity
            for the prisoners/students to contribute to print magazine content.
            Earlier this year she worked with students at Magilligan to produce
            a Project Based Learning short story anthology ‘Gaslight.’
          </Card.Text>
          <Card.Text>
            Pamela ensures that each prisoner is given access to achieving the
            highest levels of education and delivers modules in Levels 1-3 in
            Creative Writing, Presentation Skills, Employability, Learning
            Skills and Study Styles. In the past 12 years her students have won
            an astonishing 195 awards.
          </Card.Text>
          <Card.Text>
            Her students have been successful in Listowel Prison Writing
            Festival and the Koestler Arts Trust Competitions, with work
            exhibited in various locations such as the South Bank Centre,
            London. In 2021, during teaching via Zoom Classes, one of her
            students came second runner up in the Prison Reform Trust’s writing
            competition judged by the world-renowned crime writer Ryan Gattis
            based in LA.
          </Card.Text>
          <Card.Text>
            During the pandemic, Pamela designed a weekly Creative Writing
            Resource for inmates in HMP Magilligan, and had a resource available
            on the European Prison Education Association (EPEA) website. In
            total, she created over 100 writing resources.
          </Card.Text>
          <Card.Text>
            One of her students said: “Thank you for all your help, support, and
            encouragement over the years. My dream as a boy was to one day
            become a published author, with your assistance that dream is
            looking more likely.”
          </Card.Text>
          <Card.Text>
            Pamela has now been shortlisted to win one of just 16 Gold Awards,
            which will be announced and celebrated at a gala ceremony in London
            on 25 November and televised on the BBC, with winners showcased on
            The One Show.
          </Card.Text>
          <Card.Text>
            The honours have been announced on National Thank a Teacher Day, an
            annual event celebrating the role of educators across the UK for the
            valued role they play in communities and shaping young people.
          </Card.Text>
          <Card.Text>
            Last year two NWRC lecturers – Ivor Neill (Sport), and Jacqueline
            Turner (Health) received Silver Pearson Teaching Awards, while the
            NWRC Health Studies team won the Gold award for Further Education
            Team of the Year.
          </Card.Text>
          <Card.Text>
            The Pearson National Teaching Awards is an annual celebration of
            excellence in education run by the Teaching Awards Trust, an
            independent charity now in its 25th year, that recognises the
            life-changing work that takes place in education, highlighting the
            vital role educators play and the work that’s delivered in schools
            and colleges every day.
          </Card.Text>
          <Card.Text>
            Michael Morpurgo, author, former Children’s Laureate, and President
            of the Teaching Awards Trust, says: “I am inspired by the devotion
            of teachers and the huge impact they have on the lives of the young
            people they tutor, support, encourage and motivate day in and day
            out. The valuable role they play both inside and outside the
            classroom has inspired generations of young people across the
            country to achieve their potential. I am delighted to congratulate
            the winners of the 2023 Awards and thank them all for the amazing
            contributions they have made to our communities.”
          </Card.Text>
          <Card.Text>
            Sharon Hague, Senior Vice President of Schools at Pearson UK, says:
            “We would like to congratulate today’s Silver winners on their
            incredible achievements. We can’t underestimate the huge
            contribution schools make to our young people’s lives and Pamela is
            an inspiring example of the positive impact an individual can have
            on pupils and communities.”
          </Card.Text>
        </Row>
      </Container>
    </Layout>
  )
}

export default pearsonAward
